<template>
  <div>
    <el-form
      v-loading="loading"
      ref="dataForm"
      :model="dataForm"
      label-width="100px"
      class="demo-ruleForm"
      style="font-size: 14px!important;"
    >
      <el-form-item label="稿子ID" style="margin-bottom: 0px">
        <span>{{ dataForm.id }}</span>
      </el-form-item>
      <el-form-item label="状态" style="margin-bottom: 10px">
        <span>{{ dataForm.status }}</span>
      </el-form-item>
      <el-form-item
        label="名称"
        prop="name"
        id="name"
        style="margin-bottom: 25px"
        :rules="[
          {
            required: true,
            message: '请输入名称',
            trigger: ['blur', 'change'],
          },
          {
            min: 1,
            max: 15,
            message: '长度在 1 到 15 个字符',
            trigger: ['blur', 'change'],
          },
        ]"
      >
        <el-col :span="12">
          <el-input
            ref="name"
            v-model="dataForm.name"
            maxlength="15"
            placeholder="请输入名称"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        label="板型特色"
        prop="desc"
        id="desc"
        style="margin-bottom: 25px"
        :rules="[
          {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: ['blur', 'change'],
          },
        ]"
      >
        <el-col :span="12">
          <el-input
            ref="desc"
            v-model="dataForm.desc"
            maxlength="20"
            placeholder="请输入板型特色"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <!--      <el-form-item label="板型配置" style="margin-bottom: 35px">-->
      <!--        <div style="height: fit-content; display: flex; flex-direction: row; width: 100%">-->
      <!--          <div v-for="(item, index) in dataForm.pattern" :key="index" style="display: flex; flex-direction: row; width: 25%">-->
      <!--            <span style="margin: auto 10px auto 20px; padding: 10px; white-space: nowrap">{{ getPatternTitle(index) }}:</span>-->
      <!--            <el-form-item>-->
      <!--              <el-input :rows="3" type="textarea" maxlength="100" placeholder="请输入简介" v-model="item.value" show-word-limit></el-input>-->
      <!--            </el-form-item>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </el-form-item>-->

      <el-form-item label="板型配置" style="margin-bottom: 0px">
        <el-row
          style="height: fit-content; display: flex; flex-direction: row; width: 100%"
        >
          <el-col
            :span="6"
            :xs="4"
            v-for="(item, index) in dataForm.pattern"
            :key="index"
          >
            <el-row>
              <el-col
                :span="4"
                style="margin: auto 10px auto 20px; padding: 10px; white-space: nowrap; text-align: right"
                >{{ getPatternTitle(index) }}:</el-col
              >
              <el-col :span="18">
                <el-form-item
                  :id="'pattern.' + index + '.value'"
                  :required="index < 3"
                  :prop="'pattern.' + index + '.value'"
                  :rules="[
                    {
                      required: index < 3,
                      message: '请输入板型配置',
                      trigger: ['blur', 'change'],
                    },
                    {
                      min: index < 3 ? 1 : 0,
                      max: 100,
                      message:
                        '长度在 ' + (index < 3 ? 1 : 0) + ' 到 100 个字符',
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <el-input
                    ref="pattern_value"
                    :rows="6"
                    type="textarea"
                    maxlength="100"
                    placeholder="请输入简介"
                    v-model="item.value"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="作者昵称" style="margin-bottom: 0px">
        <el-row style="padding: 0px 10px;">
          <el-col :span="5" :xs="4">
            <el-form-item
              prop="author"
              id="author"
              :rules="[
                {
                  required: true,
                  message: '请输入作者昵称',
                  trigger: ['blur', 'change'],
                },
                {
                  min: 1,
                  max: 10,
                  message: '长度在 1 到 10 个字符',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-input
                ref="author"
                v-model="dataForm.author"
                maxlength="10"
                placeholder="请输入作者昵称"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col
            :span="3"
            :xs="4"
            style="text-align: right; padding: 5px 0; white-space: nowrap"
            >作者UID：</el-col
          >
          <el-col :span="5" :xs="4">
            <el-form-item
              prop="uid"
              id="uid"
              :rules="[
                {
                  required: true,
                  message: '请输入作者UID',
                  trigger: ['blur', 'change'],
                },
                {
                  min: 1,
                  max: 8,
                  message: '长度在 1 到 8 个字符',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-input
                ref="uid"
                v-model="dataForm.uid"
                maxlength="8"
                placeholder="请输入作者UID"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col
            :span="3"
            :xs="4"
            style="text-align: right; padding: 5px 0; white-space: nowrap"
            >联系方式：</el-col
          >
          <el-col :span="5" :xs="4">
            <el-form-item
              prop="contact_info"
              id="contact_info"
              :rules="[
                {
                  required: true,
                  message: '请输入联系方式',
                  trigger: ['blur', 'change'],
                },
                {
                  min: 1,
                  max: 20,
                  message: '长度在 1 到 20 个字符',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-input
                ref="contact_info"
                v-model="dataForm.contact_info"
                maxlength="20"
                placeholder="请输入联系方式"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="角色信息" style="margin-bottom: 25px">
        <div
          v-for="(item, index) in dataForm.roles"
          :key="index"
          style="border: 1px solid rgb(0, 153, 255); border-radius: 10px; position: relative; padding: 10px 10px; line-height: 20px!important; margin: 20px 0"
        >
          <!--        <legend style="color: rgb(0, 153, 255); border-color: rgb(0, 153, 255)">{{item.name}}</legend>-->
          <div
            style="background-color: #ffffff; top: -15px; position: absolute; left: 10px; height: 30px; display: flex; flex-direction: column; align-content: center; padding: 0 5px"
          >
            {{ item.name }}
          </div>
          <el-row style="padding: 5px 10px;">
            <el-col
              :span="3"
              :xs="4"
              style="text-align: right; padding: 5px 0; white-space: nowrap"
              >角色名称：</el-col
            >
            <el-col
              :span="22"
              :xs="4"
              style="white-space: normal; width: 200px"
            >
              <el-form-item
                :prop="'roles.' + index + '.name'"
                :id="'roles.' + index + '.name'"
                :rules="[
                  {
                    required: true,
                    message: '请输入角色名',
                    trigger: ['blur', 'change'],
                  },
                  {
                    min: 1,
                    max: 10,
                    message: '长度在 1 到 10 个字符',
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-input
                  ref="roles_name"
                  v-model="item.name"
                  maxlength="10"
                  placeholder="请输入角色名"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="padding: 5px 10px;">
            <el-col
              :span="3"
              :xs="4"
              style="text-align: right; padding: 5px 0; white-space: nowrap"
              >阵营：</el-col
            >
            <el-col
              :span="10"
              :xs="4"
              style="white-space: normal; width: 200px"
            >
              <el-form-item>
                <el-select v-model="item.team">
                  <el-option label="神职" :value="1"></el-option>
                  <el-option label="狼人" :value="2"></el-option>
                  <el-option label="村民" :value="3"></el-option>
                  <el-option label="第三方" :value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="padding: 5px 10px;">
            <el-col
              :span="3"
              :xs="4"
              style="text-align: right; padding: 5px 0; white-space: nowrap"
              >技能详情描述：</el-col
            >
            <el-col
              :span="20"
              :xs="4"
              style="white-space: normal; min-width: 300px"
            >
              <el-form-item
                :prop="'roles.' + index + '.desc'"
                :id="'roles.' + index + '.desc'"
                :rules="[
                  {
                    required: true,
                    message: '请输入角色技能详情描述',
                    trigger: ['blur', 'change'],
                  },
                  {
                    min: 1,
                    max: 300,
                    message: '长度在 1 到 300 个字符',
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-input
                  ref="roles_desc"
                  :rows="6"
                  v-model="item.desc"
                  type="textarea"
                  maxlength="300"
                  placeholder="请输入角色技能详情描述"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="padding: 5px 10px;">
            <el-col
              :span="3"
              :xs="4"
              style="text-align: right; padding: 5px 0; white-space: nowrap"
              >角色设计思路：</el-col
            >
            <el-col
              :span="20"
              :xs="4"
              style="white-space: normal; min-width: 300px"
            >
              <el-form-item
                :prop="'roles.' + index + '.design'"
                :id="'roles.' + index + '.design'"
                :rules="[
                  {
                    required: true,
                    message: '请输入角色设计思路',
                    trigger: ['blur', 'change'],
                  },
                  {
                    min: 1,
                    max: 500,
                    message: '长度在 1 到 500 个字符',
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-input
                  ref="roles_design"
                  :rows="3"
                  v-model="item.design"
                  type="textarea"
                  maxlength="500"
                  placeholder="请输入角色设计思路"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="padding: 5px 10px;">
            <el-col
              :span="3"
              :xs="4"
              style="text-align: right; padding: 5px 0; white-space: nowrap"
              >胜利条件：</el-col
            >
            <el-col
              :span="20"
              :xs="4"
              style="white-space: normal; min-width: 300px"
            >
              <el-form-item
                :prop="'roles.' + index + '.win_rule'"
                :id="'roles.' + index + '.win_rule'"
                :rules="[
                  {
                    required: true,
                    message: '请输入角色胜利条件',
                    trigger: ['blur', 'change'],
                  },
                  {
                    min: 1,
                    max: 100,
                    message: '长度在 1 到 100 个字符',
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-input
                  ref="roles_win_rule"
                  :rows="2"
                  v-model="item.win_rule"
                  type="textarea"
                  maxlength="100"
                  placeholder="请输入角色胜利条件"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form-item>
      <el-form-item label="Q&A" style="margin-bottom: 35px">
        <perButton
          perm="activity:save_submission"
          type="primary"
          @click="handleAddQA"
          >添加Q&A</perButton
        >
        <el-table
          :data="dataForm.q_a"
          style="margin-top:20px"
          :header-cell-style="{ background: '#409EFF' }"
          border
        >
          <el-table-column prop="q" label="问题"> </el-table-column>
          <el-table-column prop="a" label="答案"> </el-table-column>
          <el-table-column label="操作" fixed="right" width="200">
            <template slot-scope="scope">
              <perButton
                perm="activity:save_submission"
                @click="handleEditQA(scope.row)"
                >编辑</perButton
              >
              <popconfirmButton
                content="是否确认删除？"
                text="删除"
                @onConfirm="handleDeleteQA(scope.row)"
                style="margin-left: 10px"
              ></popconfirmButton>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item style="text-align: center">
        <perButton
          perm="activity:save_submission"
          type="primary"
          @click="submitForm"
          >保存</perButton
        >
      </el-form-item>
    </el-form>

    <el-dialog
      :title="q_aAction == 0 ? '编辑' : '新增'"
      :visible.sync="q_aVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="q_aForm"
        :model="q_aParams"
        label-width="80px"
        :rules="q_aRules"
      >
        <el-form-item label="问题" prop="q">
          <el-input
            v-model="q_aParams.q"
            placeholder="请输入问题"
            maxlength="300"
            :rows="2"
            type="textarea"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="答案" prop="a">
          <el-input
            v-model="q_aParams.a"
            placeholder="请输入答案"
            maxlength="300"
            :rows="2"
            type="textarea"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="changeQ_A">确定</el-button>
          <el-button @click="q_aVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { uploadOssUrl } from "@/utils/uploadUrl";
import perButton from "@/components/perm/perButton";
import { randomStr } from "@/utils/randomStr";
export default {
  name: "edit_submission",
  components: {
    perButton,
  },
  props: {},
  data() {
    return {
      loading: false,
      data_params: {},
      dataForm: {
        id: "",
        status: "",
        pattern: [],
        roles: [],
        q_a: [],
        name: "",
        desc: "",
        author: "",
        uid: "",
        contact_info: "",
        content_url: "",
      },
      q_aParams: {
        q: "",
        a: "",
      },
      q_aRow: {},
      q_aVisible: false,
      q_aAction: 0,
      q_aRules: {
        q: [
          { required: true, message: "请输入问题", trigger: "change" },
          {
            min: 1,
            max: 300,
            message: "长度在 1 到 300 个字符",
            trigger: "change",
          },
        ],
        a: [
          { required: true, message: "请输入答案", trigger: "change" },
          {
            min: 1,
            max: 300,
            message: "长度在 1 到 300 个字符",
            trigger: "change",
          },
        ],
      },
    };
  },
  async mounted() {
    let _id = this.$route.query._id;
    let act_id = this.$route.query.act_id;
    this.data_params = {
      _id,
      act_id,
    };

    await this.setFormData();
  },
  methods: {
    submitForm() {
      let self = this;
      this.$refs.dataForm.validate(async (valid, invalidFields) => {
        if (valid) {
          let d = {
            _id: this.data_params._id,
            name: this.dataForm.name,
            desc: this.dataForm.desc,
            // roles: this.dataForm.roles.map(r => {
            //   return {
            //     name: r.name,
            //     desc: r.desc
            //   }
            // }),
            pattern: this.dataForm.pattern.map((r) => r.value),
            author: this.dataForm.author,
            uid: this.dataForm.uid,
            contact_info: this.dataForm.contact_info,
            roles: this.dataForm.roles,
            q_a: this.dataForm.q_a,
          };

          let uploadRes = await this.uploadJson(d);
          if (!uploadRes || !uploadRes.length) {
            d.content_url = this.dataForm.content_url;
          } else {
            d.content_url = uploadRes;
          }

          let { data, errorCode } = await this.$http.saveSubmission(
            this.data_params.act_id,
            d
          );
          if (errorCode != "0000") {
            this.$message.error("保存失败");
            return;
          }

          this.$message({
            type: "success",
            message: "保存成功",
          });

          this.$bus.$emit("onSubmissionChanged", data);
        } else {
          console.log("error submit!!");
          console.log(invalidFields);
          let fileds = Object.keys(invalidFields);
          if (fileds.length) {
            let id1 = fileds[0];
            if (document.getElementById(id1)) {
              document
                .getElementById(id1)
                .scrollIntoView({ behavior: "smooth" });
            }

            let pattern = /\.(\d+)\./;
            if (pattern.test(id1)) {
              let match = id1.match(pattern);
              let index = parseInt(match[1]);
              let refKey = id1.replace(/\.\d+\./g, "_").replace(/\./g, "_");
              if (self.$refs[refKey]) {
                self.$refs[refKey][index].focus();
              }
            } else {
              let refKey = id1.replace(/\.\d+\./g, "_").replace(/\./g, "_");
              if (self.$refs[refKey]) {
                self.$refs[refKey].focus();
              }
            }
          }
          return false;
        }
      });
    },
    async setFormData() {
      if (
        !this.data_params ||
        !this.data_params._id ||
        !this.data_params._id.length ||
        !this.data_params.act_id ||
        !this.data_params.act_id.length
      ) {
        return;
      }

      this.loading = true;
      this.dataForm = {
        id: "",
        status: "",
        pattern: [],
        roles: [],
        q_a: [],
        name: "",
        desc: "",
        author: "",
        uid: "",
        contact_info: "",
        content_url: "",
      };

      let { data, errorCode } = await this.$http.detailSubmission({
        _id: this.data_params._id,
        act_id: this.data_params.act_id,
      });

      let submission_data = data;

      if (submission_data && submission_data._id) {
        Object.keys(this.dataForm).forEach((key) => {
          if (submission_data[key]) {
            this.dataForm[key] = submission_data[key];
          }
        });

        this.dataForm.pattern = [];
        for (let i = 0; i < 4; i++) {
          this.dataForm.pattern.push({
            value: submission_data.pattern[i] || "",
          });
        }

        switch (submission_data.status) {
          case 0:
            this.dataForm.status = "待审核";
            break;
          case 1:
            this.dataForm.status = "已通过";
            break;
          case 2:
            this.dataForm.status = "已拒绝";
            break;
          default:
            this.dataForm.status = "";
            break;
        }

        // this.dataForm.roles = [];
        // this.dataForm.q_a = [];

        // if (submission_data.content_url && submission_data.content_url.length) {
        //   let dataDetail = await new Promise((resolve, reject) => {
        //     fetch(submission_data.content_url + "?r=" + Math.random())
        //         .then(res => res.text())
        //         .then(d => {
        //           console.log(d);
        //           resolve(JSON.parse(d));
        //         })
        //         .catch(function (err) {
        //           console.error(err);
        //           this.$message({
        //             message: '服务器异常，请联系管理员！',
        //             type: 'warning'
        //           });
        //
        //           resolve(false);
        //         });
        //   });
        //
        //   if (dataDetail) {
        //     this.dataForm.roles = dataDetail.roles;
        //     this.dataForm.q_a = dataDetail.q_a;
        //   }
        // }
      }

      this.loading = false;
    },
    getPatternTitle(index) {
      switch (index) {
        case 0:
          return "神职";
        case 1:
          return "狼人";
        case 2:
          return "村民";
        case 3:
          return "第三方";
        default:
          return "";
      }
    },
    handleAddQA() {
      this.q_aVisible = true;
      this.q_aAction = 1;
      this.q_aRow = {};
      this.$refs.q_aForm.resetFields();
      this.q_aParams = {
        q: "",
        a: "",
      };
    },
    handleEditQA(row) {
      this.q_aVisible = true;
      this.q_aAction = 0;
      // this.$refs.q_aForm.resetFields();
      this.q_aRow = row;
      this.q_aParams = {
        q: row.q,
        a: row.a,
      };
    },
    handleDeleteQA(row) {
      let index = this.dataForm.q_a.indexOf(row);
      if (index >= 0) {
        this.dataForm.q_a.splice(index, 1);
      }
    },
    changeQ_A() {
      this.$refs.q_aForm.validate((valid) => {
        if (valid) {
          if (this.q_aAction == 0) {
            this.q_aRow.q = this.q_aParams.q;
            this.q_aRow.a = this.q_aParams.a;
          } else {
            this.dataForm.q_a.push({
              q: this.q_aParams.q,
              a: this.q_aParams.a,
            });
          }
          this.q_aVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async uploadJson(d) {
      let blob = new Blob(
        [
          JSON.stringify({
            ...d,
            _id: this.data_params._id,
          }),
        ],
        { type: "application/json;charset=utf-8" }
      );

      // 这么写是因为文件转换是异步任务
      let transToFile = async (blob, fileName, fileType) => {
        return new window.File([blob], fileName, {
          type: fileType,
          charset: "utf-8",
        });
      };

      let textContain = transToFile(
        blob,
        this.data_params._id + ".json",
        "application/json"
      );

      // 转换完成后可以将file对象传给接口
      return await new Promise((resolve, reject) => {
        textContain
          .then(async (res) => {
            let file = res;
            console.log(res);
            let fileName = this.data_params._id + "_" + randomStr() + ".json";
            let path =
              uploadOssUrl().activity +
              this.data_params.act_id +
              "/" +
              fileName;
            let result = await this.$http.uploadFile(path, fileName, file);
            resolve(result);
          })
          .catch(() => {
            resolve(null);
          });
      });
    },
  },
};
</script>

<style scoped></style>
